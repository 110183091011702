import React, { useState } from 'react';
import ClearFilters from './utils/ClearFilters';
import RandomiseOrder from './utils/RandomiseOrder';
import FilterFunction from './utils/FilterFunction';
import GTMFilterPush from './GTM/GTMFilterPush';

// Create filter chips
const LightboxActions = (props) => {

	// Filter child data
	const HandleFilterClick = (event) => {
		// Run filter function
		FilterFunction('filterClick', props.filterIsActive, props.selectedOption, props.childSlides, props.errorMessageVisibility, props.setErrorMessageVisibility, props.setVisibleChildren, props.setIsLightboxOpen, props.filterInputData);

		// Call update function on carousel to reinitialise indexing, return slider to beginning
		props.swiper.update();
		props.swiper.slideTo(0, 300);
		props.swiper.lazy.load();

		// Send the filtered values to the datalayer
		GTMFilterPush(event, props.selectedOption, props.errorMessageVisibility);
	}

	return (
		<div className="lightbox-footer">
			<a className="lightbox-footer__link" href="" onClick={(e) => ClearFilters(e, props.setVisibleChildren, props.childSlides, props.setFilterInputData, props.filterInputData, props.setFilterIsActive, props.swiper, props.setSelectedOption, props.setErrorMessageVisibility, props.setIsLightboxOpen)}>{props.umbracoData.clearAll}</a>
			<button type="button" className="button" onClick={(e) => HandleFilterClick(e)}>{props.umbracoData.filterText}</button>
			<span className={`error-msg ${props.errorMessageVisibility ? '' : 'error-msg--hidden' }`}>
				<span className="icon">
					<img src="/assets/icons/error.svg" />
				</span>
				<p className="msg-text">{props.umbracoData.nodataMessage}</p>
			</span>
		</div>
	);
}

export default LightboxActions;
