import React from 'react';
import FilterFunction from './utils/FilterFunction';
import GTMFilterPush from './GTM/GTMFilterPush';

// Create checkboxes
const ActiveFilterInput = (props) => {

	// HAndle click behaviour
	const HandleActiveCheckboxClick = (event, childSlides, setFilterInputData, setSelectedOption) => {
		
		// Update FilterInputData state when filters are unchecked
		const uncheckedFilterInputData = [...props.filterInputData];
		
		uncheckedFilterInputData.map((filters, index) => {
			return filters.FilterInputs.map((input, index) => {
				// If the input you've clicked on matches one in state
				if (input.Id === event.target.id) {
					// Set to unchecked value
					input.isChecked = false;

					// Set this filter to be false in active filters object
					
					if (props.thisFilterName === 'Country') {
						var countryarray = [...props.selectedOption.Country];
						var index = countryarray.indexOf(input.Label);
						if (index !== -1) {
							countryarray.splice(index, 1);
							props.selectedOption.Country = countryarray;
						}
						if (countryarray.length==0) {
							props.filterIsActive[props.thisFilterName] = false;
							setSelectedOption({ ...props.selectedOption, [props.thisFilterName]: null });
                        }
					}
					else {
						props.filterIsActive[props.thisFilterName] = false;
						setSelectedOption({ ...props.selectedOption, [props.thisFilterName]: false });
                    }
					// Update selectedOption object with latest selected option
					// Clear relevant filter cookie
					if (props.thisFilterName === 'Gender') {
						document.cookie = 'wv_genderFilter=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
					} else if (props.thisFilterName === 'Country') {
						document.cookie = 'wv_countryFilter=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
					}
				}
			});
		});

		// Update checked/unchecked state
		setFilterInputData(uncheckedFilterInputData);

		// Run filter function
		FilterFunction('filterClick', props.filterIsActive, props.selectedOption, props.childSlides, props.errorMessageVisibility, props.setErrorMessageVisibility, props.setVisibleChildren, props.setIsLightboxOpen, props.filterInputData);
		// Call update function on carousel to reinitialise indexing, return slider to beginning
		props.swiper.update();
		props.swiper.slideTo(0, 300);
		props.swiper.lazy.load();
	};

	return (
		<div className="form__input-container">
			<label htmlFor={props.input.Id} className="checkbox" tabIndex='0'>
				<input className="checkbox__input" checked={!!props.input.isChecked} type="checkbox" id={props.input.Id} tabIndex='-1' onChange={(e) => HandleActiveCheckboxClick(e, props.childSlides, props.setFilterInputData, props.setSelectedOption)} />
				<span className="checkbox__checkmark">
					<span className="icon">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.1249 0.579361L15.965 2.67349L6.49505 15.5169L0.10112 8.55082L2.70076 6.1647L6.19756 9.97441L13.1249 0.579361Z" fill="#E7600C"/>
						</svg>
					</span>
				</span>
				<span className="checkbox__label">{props.input.Label}</span>
			</label>
		</div>
	)
};

export default ActiveFilterInput;
