// Function to check if a value exists within an object
function FindValue(o, value) {
	for (var prop in o) {
		if (o.hasOwnProperty(prop) && o[prop] === value) {
			return true;
		}
	}
	return null;
}

export default FindValue;
