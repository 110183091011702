import React from 'react';

//story # 6931 randomise the order of the resulting set of filtered children
//(otherwise returns children from selected countries in order of selection)
//randomly sort using Fisher Yates method
const RandomiseOrder = (children) => {
	for (let i = children.length - 1; i > 0; i--) {
		let j = Math.floor(Math.random() * i);
		let temp = children[i];
		children[i] = children[j];
		children[j] = temp;
	}
}

export default RandomiseOrder;
