import React, { useState } from 'react';
import CheckboxInput from '../RegisteredChildrenCarousel/CheckboxInput';
import SelectDropdownInput from '../RegisteredChildrenCarousel/SelectDropdownInput';
import LightboxActions from '../RegisteredChildrenCarousel/LightboxActions';
import CloseLightbox from './utils/CloseLightbox';

// Create contents of lightbox
const LightboxForm = (props) => {

	return (
		<div className="lightbox-inner">
			<button className="lightbox-close" aria-label="Close" onClick={(e) => CloseLightbox(props.setIsLightboxOpen)}>
				<img src="/assets/icons/times.svg" />
			</button>
			<div className="children-filters children-filters--v2">
				<p className="lightbox-title">{props.umbracoData.lightboxTitle}</p>
				{props.filterInputData.map((input, index) => { // For each type of filter
					if (input.FilterInputs.length > 0 && input.InputType == 'select')
					{
						return <SelectDropdownInput key={index} input={input} filterInputData={props.filterInputData} filterIsActive={props.filterIsActive} setFilterIsActive={props.setFilterIsActive} thisFilterName={input.FilterName}  selectedOption={props.selectedOption} setSelectedOption={props.setSelectedOption} selectPlaceholder={props.umbracoData.selectPlaceholder}></SelectDropdownInput>
					}
					else if (input.FilterInputs.length > 0 && input.InputType == 'checkbox') {
						// Map to relevant input type - checkbox
						return <CheckboxInput key={index} input={input} filterInputData={props.filterInputData} filterIsActive={props.filterIsActive} setFilterInputData={props.setFilterInputData} thisFilterName={input.FilterName} setSelectedOption={props.setSelectedOption} selectedOption={props.selectedOption}></CheckboxInput>
					}
					else // For handling empty Birthday dropdown
					{
						return <SelectDropdownInput key={index} input={input} filterInputData={props.filterInputData} filterIsActive={props.filterIsActive} setFilterIsActive={props.setFilterIsActive} thisFilterName={input.FilterName}  selectedOption={props.selectedOption} setSelectedOption={props.setSelectedOption} selectPlaceholder={props.umbracoData.selectPlaceholder}></SelectDropdownInput>
					}
				})}
				<LightboxActions umbracoData={props.umbracoData} visibleChildren={props.visibleChildren} setVisibleChildren={props.setVisibleChildren} setFilterInputData={props.setFilterInputData} filterInputData={props.filterInputData} setFilterIsActive={props.setFilterIsActive} filterIsActive={props.filterIsActive} childSlides={props.childSlides} swiper={props.swiper} setSelectedOption={props.setSelectedOption} selectedOption={props.selectedOption} errorMessageVisibility={props.errorMessageVisibility} setErrorMessageVisibility={props.setErrorMessageVisibility} setIsLightboxOpen={props.setIsLightboxOpen}></LightboxActions>
			</div>
		</div>
	);
}

export default LightboxForm;
