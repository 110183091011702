import React from 'react';

import GTMPushChildInfo from '../GTM/GTMPushChildInfo';

// Log which child is visible as the user scrolls
const GTMScrollPush = (slides, currentIndex, umbracoData, gtmVisibleItems) => {

	let scrolledItems = [];

	slides.forEach((item, i) => {
		// If this does not already exist in gtmVisibleItems array
		if (!gtmVisibleItems.includes(item)) {
			if (!scrolledItems.includes(item)) {
				if (item.classList.contains('swiper-slide-visible')) {
					// Push visible items to array
					gtmVisibleItems.push(item);
					scrolledItems.push(item);
					GTMPushChildInfo(scrolledItems, umbracoData);

					return false;
				}
			}
		}
	});
}

export default GTMScrollPush;
