// Get data attributes from parent element for Umbraco content
const GetDataAttributes = (el) => {
	const data = {};
	[].forEach.call(el.attributes, function(attr) {
		if (/^data-/.test(attr.name)) {
			const camelCaseName = attr.name.substr(5).replace(/-(.)/g, function($0, $1) {
				return $1.toUpperCase();
			});
			data[camelCaseName] = attr.value;
		}
	});
	return data;
};

export default GetDataAttributes;
