import React from 'react';

// Create checkboxes
const CheckboxInput = (props) => {

	// Create array based on selected checkboxes
	const HandleCheckboxClick = (event, childSlides, setFilterInputData, setSelectedOption) => {
		// Update FilterInputData state when filters are checked/unchecked
		const updatedFilterInputData = [...props.filterInputData];

		updatedFilterInputData.map((filters, index) => {
			return filters.FilterInputs.map((input, index) => {
				// If the input you've clicked on matches one in state
				if (input.Id === event.target.id) {
					if (input.isChecked) { // UNCHECKING INPUT
						// Set to opposite value to create checked/unchecked behaviour
						input.isChecked = false;
						// Set this filter to be false in active filters object
						props.filterIsActive[props.thisFilterName] = false;

						// Update selectedOption object with latest selected option
						setSelectedOption({ ...props.selectedOption, [props.thisFilterName]:false});

					} else { // CHECKING INPUT
						input.isChecked = true;
						// Set this filter to be true in active filters object
						props.filterIsActive[props.thisFilterName] = true;
						// Update selectedOption object with latest selected option
						setSelectedOption({ ...props.selectedOption, [props.thisFilterName]:true});
					}
				}
			});
		});

		// Update checked/unchecked state
		setFilterInputData(updatedFilterInputData);
	}

	return (
		<div className="form__input-container">
			<label htmlFor={props.input.FilterName} className="checkbox" tabIndex='0'>
				<input className="checkbox__input" checked={!!props.input.FilterInputs[0].isChecked} type="checkbox" id={props.input.FilterName} tabIndex='-1' onChange={(e) => HandleCheckboxClick(e, props.childSlides, props.setFilterInputData, props.setSelectedOption)} />
				<span className="checkbox__checkmark">
					<span className="icon">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.1249 0.579361L15.965 2.67349L6.49505 15.5169L0.10112 8.55082L2.70076 6.1647L6.19756 9.97441L13.1249 0.579361Z" fill="#E7600C"/>
						</svg>
					</span>
				</span>
				<span className="checkbox__label">{props.input.FilterLabel}</span>
			</label>
		</div>
	)
};

export default CheckboxInput;
