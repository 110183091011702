import React from 'react';

// Log which filters were selected when a user chooses to filter
const GTMFilterPush = (event, selectedFilters, errorMessageVisibility) => {

	const genderSelection = selectedFilters.Gender ? selectedFilters.Gender.toLowerCase() : 'null';
	const countrySelection = selectedFilters.Country ? selectedFilters.Country.toString().toLowerCase() : 'null';
	const ageSelection = selectedFilters.Age;
	const errorMessageEl = event.target.parentNode.querySelector('.error-msg');

	// Ensure state change has happened before tracking filter
	setTimeout(() => {
		const errorMessage = errorMessageEl.classList.contains('error-msg--hidden') ? '' : 'No child data available to display: ';

		if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
			dataLayer.push({
				'event': 'filterSubmission',
				'event_category': 'form tracking',
				'event_action': 'filter submission',
				'event_label': errorMessage + 'country:' + countrySelection + '_gender:' + genderSelection + '_age:' + ageSelection
			});
		}
	}, 500);
}

export default GTMFilterPush;
