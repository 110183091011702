import React from 'react';

// Close lightbox
const CloseLightbox = (setIsLightboxOpen) => {
	// Enable body to scroll
	document.body.classList.remove('body--lightbox-active');
	// Close lightbox
	setIsLightboxOpen(false);
}

export default CloseLightbox;
