import React from 'react';

// Log which child a user clicked on in GTM
const GTMChildClick = (event) => {
	const clickedChild = event.target.offsetParent.offsetParent.offsetParent;
	const childFirstName = clickedChild.querySelector('.child__text-heading').innerText;
	const childPosition = clickedChild.dataset.index;

	if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
		dataLayer.push({
			'event': 'eec_click',
			'event_category': 'ecommerce',
			'event_action': 'child click',
			'event_label': childFirstName,
			'event_value': '',
			'non_interaction': false,
			'ecommerce': {
				'currency': '',
				'click': {
					'actionField': { 'list': 'child-sponsorship' },
					'products': [{
						'name': 'Child Sponsorship',
						'id': 2,
						'brand': 'child-sponsorship',
						'category': 'recurring-donation',
						'variant': '',
						'price': '',
						'quantity': 1,
						'coupon': '',
						'position': childPosition
					}]
				}
			}
		});
	}
}

export default GTMChildClick;
