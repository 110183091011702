import React, { useState, useEffect, useRef } from 'react';


const MultiSelectDropdown = (props) => {
    const refOne = useRef(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
    }, []);
    const handleClickOutside = (e) => {
        if (refOne.current.contains(e.target)) {
            document.querySelector(".multiselect__dropdown").classList.toggle('active');
        }
        else {
            if (!(e.target.classList.contains("multiselect__dropdown--option") || 
            e.target.classList.contains("multiselect__dropdown--option-checkbox") || 
            e.target.classList.contains("select-option__icon") || 
            e.target.classList.contains("dropdown__list-item")))
            {
                document.querySelector(".multiselect__dropdown").classList.remove('active');
            }
        }

        if (e.target.classList.contains("lightbox-footer__link"))
        {
            setSelectedCountry([]);
        }
    }
    const [selectedCountry, setSelectedCountry] = useState(props.input.FilterInputs.filter(item => item.isChecked === true).map(x => x.Label));
    
    useEffect(() => {
        if (props.selectedOption.Country != null) {
            setSelectedCountry(props.selectedOption.Country);
        }
    }, [props.selectedOption.Country]);

    const toggleOption = ({ id }) => {
        setSelectedCountry( () => {
            // if it's in, remove
            const newArray = props.selectedOption.Country == null ? [] : [...props.selectedOption.Country];
            if (newArray.includes(id)) {
                return newArray.filter(item => item != id);
            // else, add
            } else {
                newArray.push(id)
                return newArray;
            }
        });
        props.setSelectedOption(prevSelected => {
            // if it's in, remove
            const newArray = prevSelected.Country == null ? [] : [...prevSelected.Country];
            if (newArray.includes(id)) {
                if(newArray.filter(item => item != id).length > 0)
                    props.filterIsActive[props.thisFilterName] = true;
                else 
                    props.filterIsActive[props.thisFilterName] = false;
                return { ...props.selectedOption, [props.thisFilterName]: newArray.filter(item => item != id) };
            // else, add
            } else {
                newArray.push(id);
                if(newArray.length > 0)
                    props.filterIsActive[props.thisFilterName] = true;
                else 
                    props.filterIsActive[props.thisFilterName] = false;
                return { ...props.selectedOption, [props.thisFilterName]: newArray };
            }
        });
    }
   
 
    return (
        
        <div className={`multiselect__dropdown react-dropdown rt-${props.thisFilterName}`} >
            <div className="multiselect__dropdown__selected dropdown__header" ref={refOne}>
                <div>{selectedCountry.length > 0 ? selectedCountry.sort().join(", ") : props.selectPlaceholder}</div>
                <span className="dropdown__icon">
                    <svg width="12" height="8" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 2.83155L9 12L-1.23771e-07 2.83155L2.77952 -6.65308e-07L9 6.33691L15.2205 -1.21497e-07L18 2.83155Z" fill="#E7600C" />
                    </svg>
                </span>
            </div>
            <ul className="multiselect__dropdown--options dropdown__list" >
                {props.input.FilterInputs.map((option, index)=> {
                    const isSelected  = props.selectedOption.Country != null ? 
                                            props.selectedOption.Country.includes(option.Label) : false;

                    return (
                        <li key={index} className="multiselect__dropdown--option" onClick={() => toggleOption({ id: option.Label })}>
                            <input type="checkbox" checked={isSelected} className="multiselect__dropdown--option-checkbox" readOnly></input>
                            <span className="select-option__icon select-option__icon--suffix">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.1249 0.579361L15.965 2.67349L6.49505 15.5169L0.10112 8.55082L2.70076 6.1647L6.19756 9.97441L13.1249 0.579361Z" fill="#E7600C"></path>
                                </svg>
                            </span>
                            <span className="dropdown__list-item" key={option.Id} id={option.Id} value={option.Label}>{option.Label}</span>
                         
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default MultiSelectDropdown;