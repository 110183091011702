import React from 'react';

// Debounce function to control e.g. frequency of resize function firing
const Debounce = (fn, ms) => {
	let timer
	return _ => {
		clearTimeout(timer)
		timer = setTimeout(_ => {
			timer = null
			fn.apply(this)
		}, ms)
	};
}

export default Debounce;
