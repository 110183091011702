import React from 'react';

// Push title of slide to GTM datalayer
const GTMPushChildInfo = (slideArray, umbracoData) => {
	// Loop through each child and add to impressions array
	let impressions = [];
	// Push each item in array to GTM
	slideArray.forEach((item, i) => {
		// Assign variables
		let childFirstName = item.querySelector('.child__text-heading').innerText;
		let childPosition = i + 1;

		let child = {
			'name': 'Child Sponsorship',
			'id': 2,
			'list': childFirstName,
			'brand': 'child-sponsorship',
			'category': 'recurring-donation',
			'variant': '',
			'position': childPosition,
			'price': ''
		};

		impressions.push(child);
	});

	let currency = umbracoData.currency;

	if(currency != null && typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined'){
		// GTM tracking event
		dataLayer.push({
			'event': 'eec_impression',
			'event_category': 'ecommerce',
			'event_action': 'impression',
			'event_label': 'child-sponsorship',
			'event_value': '',
			'non_interaction': true,
			'ecommerce': {
				'currency': currency,
				'impressions': impressions
			}
		});
	}
};

export default GTMPushChildInfo;
