import React from 'react';

import GTMPushChildInfo from '../GTM/GTMPushChildInfo';

// Log which children are visible when the widget becomes visible in the viewport
const GTMTrackEvents = (umbracoData, gtmVisibleItems) => {

	let viewedCarousels = [];

	if(!umbracoData.gtm != null) {
		// 1. Push visible slides to GTM
		// Find visible slides
		let slides = document.querySelectorAll('.swiper-slide-visible');

		var createSlideArray = function (slides) {
			let i;
			for (i = 0; i < slides.length; i++) {
				const slideItem = slides[i];

				if (slideItem.classList.contains('swiper-slide-visible')) {
					// Push visible items to array
					gtmVisibleItems.push(slideItem);
				}
			}

			// Determine if an element is fully in the visible viewport
			const thisCarousel = document.getElementById('RegisteredChildrenWidget');

			var observer = new IntersectionObserver(function(entries) {
				if (entries[0].isIntersecting === true) {
					// stop observing this carousel once it has been viewed
					if (viewedCarousels.includes('RegisteredChildrenWidget')) {
						observer.unobserve(thisCarousel);
					} else {
						viewedCarousels.push('RegisteredChildrenWidget');
						GTMPushChildInfo(gtmVisibleItems, umbracoData);
					}
				}
			}, { threshold: [0.98] });

			observer.observe(thisCarousel);
		}

		// Push visible slides on first load
		createSlideArray(slides);
	}
}

export default GTMTrackEvents;
