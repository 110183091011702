import React from 'react';
import CloseLightbox from './CloseLightbox';

// Open/close dialogs and overlay from clicking filter chips
const ClearFilters = (event, setVisibleChildren, childSlides, setFilterInputData, filterInputData, setFilterIsActive, swiper, setSelectedOption, setErrorMessageVisibility, setIsLightboxOpen) => {
	event.preventDefault();

	// Clear filter cookies
	document.cookie = 'wv_countryFilter=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	document.cookie = 'wv_genderFilter=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

	// Clear any checked inputs
	let clearedCheckboxes = [...filterInputData];

	clearedCheckboxes.map((filters,index) => {
		return filters.FilterInputs.map((input,index) => {
			if(input.isChecked){
				// Uncheck all checked inputs
				input.isChecked = false;
			}
		});
	});
	setFilterInputData(clearedCheckboxes);

	// Set filters to be inactive
	setFilterIsActive({ Gender: false, Country: false, Age: false, Orphan: false, Waiting: false });
	// Clear all selected options
	setSelectedOption({ Gender: null, Country: null, Age: null, Orphan: null, Waiting: null });

	// Hide error message
	setErrorMessageVisibility(false);

	// Set filtered children to original array
	setVisibleChildren(childSlides);

	// Close the lightbox
	CloseLightbox(setIsLightboxOpen);

	// Return to first slide
	swiper.update();
	swiper.slideTo(0, 300);
}

export default ClearFilters;
