// Check failed function from API requests
const CheckFailed = (then) => {
	return function (responses) {
		const someFailed = responses.some(response => response.error)

		if (someFailed) {
			throw responses
		}

		return then(responses)
	}
}

export default CheckFailed;
