import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SwiperCore, { Navigation, Lazy, Keyboard, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import TagManager from 'react-gtm-module'
// install Swiper modules
SwiperCore.use([Navigation, Lazy, Keyboard, A11y]);

import GetDataAttributes from "../../utils/GetDataAttributes";
import CheckFailed from "../../utils/CheckFailed";
import FindValue from "../../utils/FindValue";
import Wrapper from '../../components/helpers/Wrapper';
import CarouselControls from '../RegisteredChildrenCarousel/CarouselControls';
import LightboxActions from '../RegisteredChildrenCarousel/LightboxActions';
import SelectDropdownInput from '../RegisteredChildrenCarousel/SelectDropdownInput';
import LightboxForm from '../RegisteredChildrenCarousel/LightboxForm';
import FilterFunction from './utils/FilterFunction';
import CarouselParams from './utils/CarouselParams';
import Debounce from './utils/Debounce';
import CloseLightbox from './utils/CloseLightbox';
import ActiveFilterInput from '../RegisteredChildrenCarousel/ActiveFilterInput';
// Import GTM functions
import GTMChildClick from '../RegisteredChildrenCarousel/GTM/GTMChildClick';
import GTMTrackEvents from '../RegisteredChildrenCarousel/GTM/GTMTrackEvents';
import GTMPushChildInfo from '../RegisteredChildrenCarousel/GTM/GTMPushChildInfo';
import GTMScrollPush from '../RegisteredChildrenCarousel/GTM/GTMScrollPush';

const RegisteredChildrenCarousel = (props) => {
	// Reference for swiper carousel
	const swiperRef = useRef(null);
	const [swiper, setSwiper] = useState(null);

	// Set initial states of data: children and filter arrays
	const [childSlides, setChildSlides] = useState([]);
	const [umbracoData, setUmbracoData] = useState([]);
	const [filterInputData, setFilterInputData] = useState([]);

	// Which children are visible on the main carousel
	const [visibleChildren, setVisibleChildren] = useState([]);
	// Array of checked values, e.g. 'BGD', 'Girl'
	const [checkedValues, setCheckedValues] = useState([]);
	// Set state of active filters
	const [filterIsActive, setFilterIsActive] = useState({ Gender: false, Country: false, Age: false, Orphan: false, Waiting: false });
	// Visible carousel items for GTM
	const [gtmVisibleItems, setGtmVisibleItems] = useState([]);
	// GTM refresh state
	const [gtmRefresh, setGtmRefresh] = useState(false);
	// Check initial filter state
	const [initialFilterCheck, setInitialFilterCheck] = useState(false);
	// Gender step of filtering process
	const [genderStep, setGenderStep] = useState([]);
	// Show child video
	const [showVideo, setShowVideo] = useState(false);
	// Selected option for inputs
	const [selectedOption, setSelectedOption] = useState({ Gender: null, Country: null, Age: null, Orphan: null, Waiting: null });
	// State to control visibility of error message
	const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);
	// State to control whether or not lightbox is open
	const [isLightboxOpen, setIsLightboxOpen] = useState(false);

	// API requests add to array so they can be passed to async function
	const promises = []

	let windowWidth = window.innerWidth;

	// Check promises resolve
	const promisesResolved = promises.map(promise => promise.catch(error => ({ error })))

	// Use effect hook only fires once
	useEffect(() => {
		const fetchData = async () => {
			axios.all(promisesResolved)
			.then(CheckFailed(([filtersURL]) => {
				// Load in initial slide images
				swiperRef.current.swiper.lazy.load();
				// Bring data from Umbraco out of data attributes and into state
				const parent = document.getElementById('RegisteredChildrenWidget');
				const parentData = GetDataAttributes(parent);
				setUmbracoData(parentData);
				setGtmRefresh(true);
				// Set initial state of child data
				setChildSlides(JSON.parse(parentData.childData));
				// Set initial state of filtered children
				setVisibleChildren(JSON.parse(parentData.childData));
				// Set filter input data
				setFilterInputData(JSON.parse(parentData.filterData));
				props.hideLoader(props.loader, props.container);
				setInitialFilterCheck(true);
			}))
			.catch((err) => {
				console.log('Registered Children widget API failed', err)
				props.hideLoader(props.loader, props.container);
			});
		};
		fetchData();
		setGtmRefresh(false);
		setInitialFilterCheck(false);
	}, []);

	// GTM tracking fires when the Umbraco data has loaded
	useEffect(() => {
		setTimeout(() => {
			GTMTrackEvents(umbracoData, gtmVisibleItems);
		}, 0);

		if(swiper !== null) { // Push slide data as you scroll through the carousel
			swiper.on('activeIndexChange', () => {
				GTMScrollPush(swiper.slides, swiper.activeIndex, umbracoData, gtmVisibleItems);
			});
		}

		const debouncedHandleResize = Debounce(function handleResize() {
			windowWidth = window.innerWidth;
			if (windowWidth > 1023) {
		 		setShowVideo(true);
		 	} else {
		 		setShowVideo(false);
		 	}
		}, 1000)

		debouncedHandleResize();

		window.addEventListener('resize', debouncedHandleResize)
	}, [gtmRefresh]);

	// Reload images any time DOM elements are filtered
	if(swiper !== null) {
		swiper.on('observerUpdate', () => {
			swiper.lazy.load();
		});
	}

	// Initial filter fires when the Umbraco data has loaded
	useEffect(() => {
		// New filters
		// Activate any checked filters
		let checkedFilters = [...filterInputData];

		let checkedCountries = [];

		checkedFilters.map((filter, index) => {
			// Update selectedOption object with latest selected options
			if (filter.InputType === 'select') { // For select dropdowns
				return filter.FilterInputs.map((input, index) => {
					if (input.isChecked) {
						// Filter by multiple countries if selected
						if (filter.FilterName === 'Country') {
							checkedCountries.push(input.Label);
							// Update the values in select option state
							selectedOption[filter.FilterName] = checkedCountries;
							// Set this filter to be active
							filterIsActive[filter.FilterName] = true;
						} else { // For other types of single dropdown filters
							// Update the value in select option
							selectedOption[filter.FilterName] = input.Label;
							// Set this filter to be active
							filterIsActive[filter.FilterName] = true;
						}
					}
				});
			} else { // For checkboxes
				return filter.FilterInputs.map((input, index) => {
					if (input.isChecked) {
						// Update the value in select option
						selectedOption[filter.FilterName] = true;
						// Set this filter to be true
						filterIsActive[filter.FilterName] = true;
					}
				});
			}
		});

		// Run filter function
		FilterFunction('pageLoad', filterIsActive, selectedOption, childSlides, errorMessageVisibility, setErrorMessageVisibility, setVisibleChildren, setIsLightboxOpen);
		// Don't show the error message on initial page load
		setErrorMessageVisibility(false);
	}, [initialFilterCheck]);

	// Open lightbox
	const OpenLightbox = () => {
		// Prevent body from scrolling
		document.body.classList.add('body--lightbox-active');
		// Open lightbox
		setIsLightboxOpen(true);
	}

	return (
		<Wrapper>
			<React.Fragment>
				<div className={`overlay overlay--lightbox ${isLightboxOpen ? 'overlay--lightbox--visible' : ''}`} onClick={(e) => CloseLightbox(setIsLightboxOpen)}></div>
				{umbracoData.hidefilterbutton == 'False' &&
					<div className="filter-controls">
						<button className="button button--secondary button--suffix-icon" aria-label="label text" data-lightbox="RC_Filters" onClick={(e) => OpenLightbox()}>
							{umbracoData.filterText}
							<img src="/assets/icons/chevron-right.svg" width="8" height="12" className="ml-1" />
						</button>
						{umbracoData.showActiveFilters == 'True' &&
							<div className="active-filter-container">
								{filterInputData.map((filter, index) => { // For each type of filter
									return filter.FilterInputs.map((input, index) => {
										// Map checkbox inputs to active filters
										if (input.isChecked === true) {
											return <ActiveFilterInput key={index} input={input} childSlides={childSlides} setFilterInputData={setFilterInputData} setSelectedOption={setSelectedOption} filterInputData={filterInputData} selectedOption={selectedOption} filterIsActive={filterIsActive} thisFilterName={filter.FilterName} errorMessageVisibility={errorMessageVisibility} setErrorMessageVisibility={setErrorMessageVisibility} setIsLightboxOpen={setIsLightboxOpen} setVisibleChildren={setVisibleChildren} swiper={swiper}></ActiveFilterInput>
										}
									})
								})}
							</div>
						}
					</div>
				}
				<div className={`lightbox ${isLightboxOpen ? 'lightbox--visible' : ''}`} id="RC_Filters">
					<LightboxForm umbracoData={umbracoData} visibleChildren={visibleChildren} setVisibleChildren={setVisibleChildren} setFilterInputData={setFilterInputData} filterInputData={filterInputData} setFilterIsActive={setFilterIsActive} filterIsActive={filterIsActive} childSlides={childSlides} swiper={swiper} selectedOption={selectedOption} setSelectedOption={setSelectedOption} errorMessageVisibility={errorMessageVisibility} setErrorMessageVisibility={setErrorMessageVisibility} setIsLightboxOpen={setIsLightboxOpen}></LightboxForm>
				</div>
			</React.Fragment>
			<Swiper ref={swiperRef} {...CarouselParams} onSwiper={(s) => {setSwiper(s);}}>
				{visibleChildren.length > 0 &&
				visibleChildren.map((child,index) => {
					const nameVal = '{name}';
					let ctaText = '';
					// Function to replace {child} with child name if included on button
					if (umbracoData.ctaCopy) {
						if(umbracoData.ctaCopy.includes(nameVal)) {
							ctaText = umbracoData.ctaCopy.replace(nameVal, child.Name);
						} else {
							ctaText = umbracoData.ctaCopy;
						}
					}

					let childImage;
					const girlImagePath = "/assets/img/children-fallback/girl.png";
					const boyImagePath = "/assets/img/children-fallback/boy.png";
					// Replace child image with placeholder if they are not from a media publishable country, or if they have no image available
					if (child.MediaPublishableCountry == true) {
						if (child.PhotoUrl.length > 0) {
							childImage = child.PhotoUrl;
						} else { // If no child image available
							if (child.GenderInEnglish == 'Girl') {
								childImage = girlImagePath
							} else {
								childImage = boyImagePath
							}
						}
					} else { // If child is not from media publishable country
						if (child.GenderInEnglish == 'Girl') {
							childImage = girlImagePath
						} else {
							childImage = boyImagePath
						}
					}
					// Map registered children data to individual slides
					return <SwiperSlide key={index}>
						<div className='child' onClick={(e) => GTMChildClick(e)}>
							<a className='child__link' href={`${umbracoData.donationpageurl}?childId=${child.Id}&multiplechildren=${umbracoData.multiplechildrensponsor}`} aria-label={child.Name} data-index={index}>
								{umbracoData.childimageDisable != 'True' &&
									<div className='child__image-container'>
										<div className='image image--portrait'>
										<img key={childImage} className='image__src swiper-lazy' data-src={childImage} alt={child.Name} />
										</div>
									</div>
								}
								{showVideo === true && child.VideoUrl && // Only show if a video URL exists
									<div className="child__video-container">
										<div className="video video--portrait">
											<React.Fragment key={child.VideoUrl}> {/* Fragment added to enable video src to update on filtering */}
												<video preload="none" className="video__src child-video" muted="muted" onMouseOver={e => e.target.play()} onMouseOut={e => e.target.pause()}>
													<source src={child.VideoUrl} type="video/mp4" />
													Your browser does not support the video tag.
												</video>
											</React.Fragment>
										</div>
									</div>
								}
								<div className='child__text'>
									<span className='overline child__date x-small'>{child.Country}</span>
									<div className='info-tab__container'>
										<span className="info-tab info-tab--green"><span className="info-tab__text">{child.Gender}</span></span>
									</div>
									<div className='info-tab__container'>
										<span className="info-tab info-tab--yellow"><span className="info-tab__text">{child.Age} {umbracoData.ageText}</span></span>
									</div>
									<span className='child__text-heading intro'>{child.Name}</span>
									<span className="button">{ ctaText }</span>
								</div>
							</a>
						</div>
					</SwiperSlide>
				})}
				{visibleChildren.length ==0  &&
					<p>{umbracoData.nodataMessage}</p>
				}
			</Swiper>
			<CarouselControls nextText={ umbracoData.nextLabel } prevText={ umbracoData.previousLabel }></CarouselControls> {/* Carousel prev and next button */}
			{(umbracoData.secondaryCtaLink && umbracoData.secondaryCtaTitle) && // Only show if secondary CTA has a link and title
				<div className="childrenCarousel__footer">
					<a className="button button--secondary" aria-label={ umbracoData.secondaryCtaTitle } href={ umbracoData.secondaryCtaLink } target={ umbracoData.secondaryCtaTarget }>{ umbracoData.secondaryCtaTitle }</a>
				</div>
			}
		</Wrapper>
	);
}


export default RegisteredChildrenCarousel;
