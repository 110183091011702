import React from 'react';

// Create carousel controls
const CarouselControls = (props) => (
	<ul className="carousel__controls carousel__controls--v2  carousel__controls--child carousel__controls-childWidget" aria-controls="@currentListItemId">
		<li className="carousel__prev" data-controls="prev">
			<button className="swiper-button-prev button carousel__control--inner" aria-label="{props.prevText}">
				<span className="icon">
					<img src="/assets/icons/white/chevron-left.svg" width="12" height="18" className="w-50" />
				</span>
			</button>
		</li>
		<li className="carousel__next" data-controls="next">
			<button className="swiper-button-next button carousel__control--inner" aria-label="{props.nextText}">
				<span className="icon">
					<img src="/assets/icons/white/chevron-right.svg" width="12" height="18" className="w-50" />
				</span>
			</button>
		</li>
	</ul>
);

export default CarouselControls;
