import React from 'react';
import CloseLightbox from './CloseLightbox';

// Filter the children - different behaviour for each filter
const FilterFunction = (event, filterIsActive, selectedOption, childSlides, errorMessageVisibility, setErrorMessageVisibility, setVisibleChildren, setIsLightboxOpen, filterInputData) => {
	let activeFilterCount = 0;
	let filteredChildren = childSlides;
	let filteredChildrenCountry = [];
	let filteredChildrenGender = [];
	let filteredChildrenAge = [];
	// Pre-selected filters
	let countriesToFilterBy = [];
	let gendersToFilterBy = [];
	let ageToFilterBy = [];

	// Is country filter selected? Which children have that country
	if (filterIsActive.Country) {
		activeFilterCount++;
		filteredChildren.filter(function (item) {
			if (!item['Country'] === undefined || selectedOption.Country.includes(item['Country'])) {

				// Set session cookie to selected filter option only when filter button is clicked (not on page load)
				if (filterInputData != undefined && event === 'filterClick') {

					// Get country ISO code from filter label
					let countryObject = filterInputData.find(obj => {
						return obj.FilterInputs;
					});

					// Reset any other country searches
					filterInputData[0].FilterInputs.map((filter, index) => {
						filter.isChecked = false;
					});

					let thisCountryData = countryObject.FilterInputs.filter(obj => {
						// Return relevant country
						return selectedOption.Country.includes(obj.Label);
					});

					// Set this country to be checked in filterInputData
					countriesToFilterBy = thisCountryData.map(x => x.Id);

					var countryFilter = JSON.stringify(countriesToFilterBy);
					document.cookie = 'wv_countryFilter=' + countryFilter + ';path=/'; // Session cookie
					
					filterInputData[0].FilterInputs.forEach(item => {
						if (countriesToFilterBy.find(item2 => item2 == item.Id)) {
							item.isChecked = true;
						}
					});
				}

				let currentChildIndex = filteredChildrenCountry.indexOf(item); // Does this child exist in the results
				if (currentChildIndex === -1) {
					// Push the filtered child to the country filter array
					filteredChildrenCountry.push(item);
				}
			}
		});
	}
	if (filteredChildrenCountry.length > 0) {
		filteredChildren = filteredChildrenCountry;
	}

	// Is gender filter selected? Which children are that gender
	if (filterIsActive.Gender) {
		activeFilterCount++;
		filteredChildren.filter(function (item) {
			if (!item['Gender'] === undefined || item['Gender'] === selectedOption.Gender) {
				 // Set session cookie to selected filter option only when filter button is clicked (not on page load)
				if (filterInputData != undefined && event === 'filterClick') {

					// Reset any other gender searches
					filterInputData[1].FilterInputs.map((filter,index) => {
						filter.isChecked = false;
					});

					// Get gender object from filter label
					let thisGenderData = filterInputData[1].FilterInputs.find(obj => {
						// Return relevant gender
						return obj.Label === selectedOption.Gender
					});

					// Set this gender to be checked in filterInputData
					thisGenderData.isChecked = true;

					// Set session cookie to selected filter option
					let currentGenderIndex = gendersToFilterBy.indexOf(selectedOption.Gender); // Does this gender exist in the results
					if (currentGenderIndex === -1) {
						gendersToFilterBy.push(selectedOption.Gender)
					}
					var genderFilter = JSON.stringify(gendersToFilterBy);
					document.cookie = 'wv_genderFilter=' + genderFilter + ';path=/'; // Session cookie
				}
				let currentChildIndex = filteredChildrenGender.indexOf(item); // Does this child exist in the results
				if (currentChildIndex === -1) {
					
					// Push the filtered child to the gender filter array
					filteredChildrenGender.push(item);

				}
			}
		});
	}
	if (filteredChildrenGender.length > 0) {
		filteredChildren = filteredChildrenGender;
	}

	// Is age filter selected? Which children are that age
	if(filterIsActive.Age) {
		activeFilterCount++;
		let ageLower = selectedOption.Age.split('-')[0].trimEnd(); // Lower age limit
		let ageUpper = selectedOption.Age.split('-')[1].trimStart(); // Upper age limit
		filteredChildren.filter(function (item) {

			if (!item['Age'] === undefined || item['Age'] >= ageLower && item['Age'] <= ageUpper) {
				if (filterInputData != undefined && event === 'filterClick') {
					// Reset any other age searches
					filterInputData[2].FilterInputs.map((filter, index) => {
						filter.isChecked = false;
					});

					// Get age object from filter label
					let thisAgeData = filterInputData[2].FilterInputs.find(obj => {
						// Return relevant age
						return obj.Label === selectedOption.Age
					});

					// Set this age to be checked in filterInputData
					thisAgeData.isChecked = true;

					// Set session cookie to selected filter option
					let currentAgeIndex = ageToFilterBy.indexOf(selectedOption.Age); // Does this Age exist in the results
					if (currentAgeIndex === -1) {
						ageToFilterBy.push(selectedOption.Age)
					}
					//var ageFilter = JSON.stringify(ageToFilterBy);
					//document.cookie = 'wv_ageFilter=' + ageFilter + ';path=/';

					let currentChildIndex = filteredChildrenAge.indexOf(item); // Does this child exist in the results
					if (currentChildIndex === -1) {
						// Push the filtered child to the gender filter array
						filteredChildrenAge.push(item);
					}
				}
			}
		});
	}
	if (filteredChildrenAge.length > 0) {
		filteredChildren = filteredChildrenAge;
	}

	// Is orphan filter checked? Hide or show orphans
	if(filterIsActive.Orphan) {
		//activeFilterCount++;
	}

	// Concatenate child arrays
	//let concatenatedChildren = filteredChildrenCountry.concat(filteredChildrenGender).concat(filteredChildrenAge);
	let concatenatedChildren = filteredChildren;
	// Is waiting filter checked? Sorting by longest waiting
	if(filterIsActive.Waiting) {
		// If other filters are active sort these children
		if (concatenatedChildren.length > 0) {
			concatenatedChildren.sort((a, b) => parseFloat(b.MonthsWaited) - parseFloat(a.MonthsWaited));

		} else { // If no other filters are active sort all children
			const orderAllByWaiting = filteredChildren.sort((a, b) => parseFloat(b.MonthsWaited) - parseFloat(a.MonthsWaited));
			// Hide the error message
			setErrorMessageVisibility(false);

			// Close the lightbox
			CloseLightbox(setIsLightboxOpen);

			// Add all sorted children to the carousel
			setVisibleChildren(orderAllByWaiting);

			return;
		}
	}

	// Function to check if child appears as many times as there are active filters
	function filterByCount(array, count) {
		return array.filter((a, index) =>
			array.indexOf(a) === index &&
			array.reduce((acc, b) => +(a === b) + acc, 0) === count
		);
	}

	// Remaining filtered children to show in carousel
	let remainingChildren = concatenatedChildren; // filterByCount(concatenatedChildren, activeFilterCount);

	// If remaining children exist and filters are active
	if (remainingChildren.length > 0 && activeFilterCount >= 0) {
		// Hide the error message
		setErrorMessageVisibility(false);

		// Close the lightbox
		CloseLightbox(setIsLightboxOpen);

		// Add the children to the carousel
		setVisibleChildren(remainingChildren);

	} else {  // If no remaining children exist
		// Show error message and don't close the lightbox
		setErrorMessageVisibility(true);
		// Restore all children to initial children array
		setVisibleChildren(filteredChildren);
	}
}

export default FilterFunction;
