import React, { useState } from 'react';
import MultiSelectDropdown from '../RegisteredChildrenCarousel/MultiSelectDropdown';
const SelectDropdownInput = (props) => {

	const [isOpen, setIsOpen] = useState(false);
	const toggling = () => setIsOpen(!isOpen);

	// Create array based on selected options - DESKTOP
	const HandleSelectOptionClick = (optionId) => {
		// Set this filter to be true
		props.filterIsActive[props.thisFilterName] = true;

		// Close the open dropdown
		setIsOpen(false);

		// Update selectedOption object with latest selected option
		props.setSelectedOption({ ...props.selectedOption, [props.thisFilterName]:optionId});
	}

	// Create array based on selected options - MOBILE
	const HandleSelectClickMobile = () => {
		let thisFilterValueMobile = event.target.value;
		let thisFilterValueMobileNoSpace = thisFilterValueMobile.replace(/\s/g, '');
		let thisFilterNameMobile = event.target.parentNode.parentNode.querySelector("label").dataset.filtername;

		// Set this filter to be true
		props.filterIsActive[thisFilterNameMobile] = true;

		// Update selectedOption object with latest selected option
		props.setSelectedOption({ ...props.selectedOption, [thisFilterNameMobile]:thisFilterValueMobile});
	}

	const HandleOptionBlur = () => {
		// Close the open dropdown
		setIsOpen(false);
	}

	// Create Select dropdowns
	
	return (
		<div className="form__input-container">
			
			<label className="dropdown__label" data-filtername={props.thisFilterName}>{props.input.FilterLabel == "Country" ?
			props.filterInputData.filter(x => x.FilterName == "Country").map(y => y.FilterValue)
			:
			props.input.FilterLabel == "Gender" ? props.filterInputData.filter(x => x.FilterName == "Gender").map(y => y.FilterValue)
			:
			props.input.FilterLabel == "Age" ? props.filterInputData.filter(x => x.FilterName == "Age").map(y => y.FilterValue)
			:
			props.input.FilterLabel}
			</label>

			{props.input.FilterLabel == "Country" ? <MultiSelectDropdown input={props.input} filterInputData={props.filterInputData} filterIsActive={props.filterIsActive} setFilterIsActive={props.setFilterIsActive} thisFilterName={props.input.FilterName} selectedOption={props.selectedOption} setSelectedOption={props.setSelectedOption} selectPlaceholder={props.selectPlaceholder}/> :
			<div>
			<div id={props.thisFilterName} className={`react-dropdown rt-${props.thisFilterName}`} tabIndex="0" onBlur={(e) => HandleOptionBlur()} onClick={toggling}>
			 	<div className="dropdown__header">{props.filterIsActive[props.thisFilterName] === true ? props.selectedOption[props.thisFilterName].toString() : props.selectPlaceholder }</div>
			 	{isOpen && props.input.FilterInputs.length > 0 && (
			 		<div className="dropdown__list">
			 			{props.input.FilterInputs.map((option, index) => { // Map each filter option to a select option
			 				return <span className="dropdown__list-item" key={index} id={option.Id} value={option.Label} onClick={(e) => HandleSelectOptionClick(option.Label)}>{option.Label}</span>
			 			})}
			 		</div>
			 	)}
			 	<span className="dropdown__icon">
			 		<svg width="12" height="8" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			 			<path d="M18 2.83155L9 12L-1.23771e-07 2.83155L2.77952 -6.65308e-07L9 6.33691L15.2205 -1.21497e-07L18 2.83155Z" fill="#E7600C"/>
			 		</svg>
			 	</span>
					</div>
				</div>}
			 <div className="select-default__container">
				 <select className="select-default" value={props.filterIsActive[props.thisFilterName] === true ? props.selectedOption[props.thisFilterName] : props.selectPlaceholder } onChange={(e) => HandleSelectClickMobile()}>
				 	<option disabled value={props.selectPlaceholder} >{props.selectPlaceholder}</option>
	 				{props.input.FilterInputs.map((option, index) => { // Map each filter option to a select option
	 					return <option key={index} id={option.Id} value={option.Label} >{option.Label}</option>
	 				})}
	 			</select>
				<span className="dropdown__icon">
			 		<svg width="12" height="8" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			 			<path d="M18 2.83155L9 12L-1.23771e-07 2.83155L2.77952 -6.65308e-07L9 6.33691L15.2205 -1.21497e-07L18 2.83155Z" fill="#E7600C"/>
			 		</svg>
			 	</span>
			</div>
			
		</div>
	);
}

export default SelectDropdownInput;
