import React from 'react';
import SwiperCore, { Navigation, Lazy, Keyboard } from 'swiper';

// Carousel settings from https://swiperjs.com/swiper-api
const CarouselParams = {
	modules: [Navigation, Lazy],
	preloadImages: false,
	lazy: true,
	lazy: {
		loadPrevNext: true
	},
	loop: false,
	spaceBetween: 16,
	slidesOffsetBefore: 16,
	slidesPerView: 1.35,
	watchSlidesProgress: true,
	watchSlidesVisibility: true,
	watchOverflow: true,
	observer: true,
	observeParents: true,
	observeSlideChildren: true,
	keyboard: {
		enabled: true
	},
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev"
	},
	breakpoints: {
		1024: {
			slidesPerView: 4,
			slidesOffsetBefore: 0,
			spaceBetween: 24
		}
	}
};

export default CarouselParams;
