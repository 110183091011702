/* eslint-disable */
import React from 'react';
import { render } from 'react-dom';

import RegisteredChildrenCarousel from '../js/components/RegisteredChildrenCarousel/RegisteredChildrenCarousel';

// Only render rc widget if it appears on the page
const registeredChildrenContainer = document.getElementById('RegisteredChildrenWidget');

if (registeredChildrenContainer) {
	const loader = document.querySelector('.carousel-placeholder--react');
	// hide the loader when react loads data
	const hideLoader = (loader, container) => {
		// Display container to stop screen jumping
		container.classList.add('registered-children__container--active');
		// Remove loading element
		loader.remove();
	};
	render(<RegisteredChildrenCarousel hideLoader={hideLoader} loader={loader} container={registeredChildrenContainer} />, registeredChildrenContainer);
}
